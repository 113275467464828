"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubscriptionIntervalUnit = void 0;
var ESubscriptionIntervalUnit;
(function (ESubscriptionIntervalUnit) {
    ESubscriptionIntervalUnit["DAY"] = "day";
    ESubscriptionIntervalUnit["MONTH"] = "month";
    ESubscriptionIntervalUnit["WEEK"] = "week";
    ESubscriptionIntervalUnit["YEAR"] = "year";
})(ESubscriptionIntervalUnit || (exports.ESubscriptionIntervalUnit = ESubscriptionIntervalUnit = {}));
