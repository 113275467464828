"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubVideoExtension = void 0;
var EHubVideoExtension;
(function (EHubVideoExtension) {
    EHubVideoExtension[".avi"] = ".avi";
    EHubVideoExtension[".mp4"] = ".mp4";
    EHubVideoExtension[".ogv"] = ".ogv";
    EHubVideoExtension[".webm"] = ".webm";
    EHubVideoExtension[".mov"] = ".mov";
})(EHubVideoExtension || (exports.EHubVideoExtension = EHubVideoExtension = {}));
