"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECryptoPayoutStatus = exports.ECryptoPayoutNetwork = void 0;
var ECryptoPayoutNetwork;
(function (ECryptoPayoutNetwork) {
    ECryptoPayoutNetwork["TRC20"] = "trc20";
    ECryptoPayoutNetwork["ERC20"] = "erc20";
    ECryptoPayoutNetwork["SOLANA"] = "solana";
    ECryptoPayoutNetwork["TON"] = "ton";
})(ECryptoPayoutNetwork || (exports.ECryptoPayoutNetwork = ECryptoPayoutNetwork = {}));
var ECryptoPayoutStatus;
(function (ECryptoPayoutStatus) {
    ECryptoPayoutStatus["REJECTED"] = "rejected";
    ECryptoPayoutStatus["PENDING"] = "pending";
    ECryptoPayoutStatus["COMPLETED"] = "completed";
})(ECryptoPayoutStatus || (exports.ECryptoPayoutStatus = ECryptoPayoutStatus = {}));
