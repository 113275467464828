"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EKycIdentificationStatus = void 0;
var EKycIdentificationStatus;
(function (EKycIdentificationStatus) {
    EKycIdentificationStatus["IN_PROGRESS"] = "InProgress";
    EKycIdentificationStatus["AWAITING"] = "Awaiting";
    EKycIdentificationStatus["APPROVED"] = "Approved";
    EKycIdentificationStatus["COMPLETED"] = "Completed";
    EKycIdentificationStatus["REJECTED"] = "Rejected";
    EKycIdentificationStatus["PENDING"] = "Pending";
    EKycIdentificationStatus["NEED_REVIEW"] = "NeedReview";
    EKycIdentificationStatus["INVALIDATED"] = "Invalidated";
    EKycIdentificationStatus["EXPIRED"] = "Expired";
})(EKycIdentificationStatus || (exports.EKycIdentificationStatus = EKycIdentificationStatus = {}));
