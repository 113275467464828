"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubVoiceExtension = void 0;
var EHubVoiceExtension;
(function (EHubVoiceExtension) {
    EHubVoiceExtension[".wav"] = ".wav";
    EHubVoiceExtension[".mp4"] = ".mp4";
    EHubVoiceExtension[".webm"] = ".webm";
    EHubVoiceExtension[".ogg"] = ".ogg";
    EHubVoiceExtension[".opus"] = ".opus";
    EHubVoiceExtension[".oga"] = ".oga";
    EHubVoiceExtension[".spx"] = ".spx";
    EHubVoiceExtension[".flac"] = ".flac";
    EHubVoiceExtension[".axa"] = ".axa";
    EHubVoiceExtension[".m4a"] = ".m4a";
    EHubVoiceExtension[".acc"] = ".acc";
})(EHubVoiceExtension || (exports.EHubVoiceExtension = EHubVoiceExtension = {}));
