"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubDocumentMimeTypes = void 0;
var EHubDocumentMimeTypes;
(function (EHubDocumentMimeTypes) {
    EHubDocumentMimeTypes["application/msword"] = "application/msword";
    EHubDocumentMimeTypes["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    EHubDocumentMimeTypes["application/pdf"] = "application/pdf";
    EHubDocumentMimeTypes["application/vnd.ms-excel"] = "audio/webm";
    EHubDocumentMimeTypes["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
})(EHubDocumentMimeTypes || (exports.EHubDocumentMimeTypes = EHubDocumentMimeTypes = {}));
