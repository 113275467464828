"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EProductType = void 0;
var EProductType;
(function (EProductType) {
    EProductType["POST"] = "post";
    EProductType["SUBSCRIPTION"] = "subscription";
    EProductType["MESSAGE"] = "message";
    EProductType["TIPS"] = "tips";
})(EProductType || (exports.EProductType = EProductType = {}));
