"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubAudioExtension = void 0;
var EHubAudioExtension;
(function (EHubAudioExtension) {
    EHubAudioExtension[".wav"] = ".wav";
    EHubAudioExtension[".mp4"] = ".mp4";
    EHubAudioExtension[".webm"] = ".webm";
    EHubAudioExtension[".ogg"] = ".ogg";
    EHubAudioExtension[".opus"] = ".opus";
    EHubAudioExtension[".oga"] = ".oga";
    EHubAudioExtension[".spx"] = ".spx";
    EHubAudioExtension[".flac"] = ".flac";
    EHubAudioExtension[".axa"] = ".axa";
    EHubAudioExtension[".m4a"] = ".m4a";
    EHubAudioExtension[".acc"] = ".acc";
})(EHubAudioExtension || (exports.EHubAudioExtension = EHubAudioExtension = {}));
