"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubDocumentExtension = void 0;
var EHubDocumentExtension;
(function (EHubDocumentExtension) {
    EHubDocumentExtension[".doc"] = ".doc";
    EHubDocumentExtension[".docx"] = ".docx";
    EHubDocumentExtension[".pdf"] = ".pdf";
    EHubDocumentExtension[".xls"] = ".xls";
    EHubDocumentExtension[".xlsx"] = ".xlsx";
})(EHubDocumentExtension || (exports.EHubDocumentExtension = EHubDocumentExtension = {}));
