"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMessagesErrorCodes = void 0;
var EMessagesErrorCodes;
(function (EMessagesErrorCodes) {
    EMessagesErrorCodes["InternalServerError"] = "InternalServerError";
    EMessagesErrorCodes["ReplyMessageNotFound"] = "ReplyMessageNotFound";
    EMessagesErrorCodes["UserBlockThisChat"] = "UserBlockThisChat";
    EMessagesErrorCodes["YouBlockThisChat"] = "YouBlockThisChat";
    EMessagesErrorCodes["YouDontBlockThisChat"] = "YouDontBlockThisChat";
    EMessagesErrorCodes["YouAlreadyBlockThisChat"] = "YouAlreadyBlockThisChat";
    EMessagesErrorCodes["WrongChatPermissions"] = "WrongChatPermissions";
    EMessagesErrorCodes["WrongDelayMessagePermissions"] = "WrongDelayMessagePermissions";
    EMessagesErrorCodes["WrongMessagePermissions"] = "WrongMessagePermissions";
    EMessagesErrorCodes["UserNotFound"] = "UserNotFound";
    EMessagesErrorCodes["ChatNotFound"] = "ChatNotFound";
    EMessagesErrorCodes["ValidationError"] = "ValidationError";
    EMessagesErrorCodes["UnauthorizedException"] = "UnauthorizedException";
    EMessagesErrorCodes["UploadFileNotFound"] = "UploadFileNotFound";
    EMessagesErrorCodes["NotImplementedError"] = "NotImplementedError";
    EMessagesErrorCodes["ListWithSameNameAlreadyExists"] = "ListWithSameNameAlreadyExists";
    EMessagesErrorCodes["ListHasIncorrectUsers"] = "ListHasIncorrectUsers";
    EMessagesErrorCodes["ListHasCurrentUser"] = "ListHasCurrentUser";
    EMessagesErrorCodes["ListNotFound"] = "ListNotFound";
    EMessagesErrorCodes["DelayMessageNotFound"] = "DelayMessageNotFound";
    EMessagesErrorCodes["DelayMessageAlreadySend"] = "DelayMessageAlreadySend";
})(EMessagesErrorCodes || (exports.EMessagesErrorCodes = EMessagesErrorCodes = {}));
