"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHubPhotoExtension = void 0;
var EHubPhotoExtension;
(function (EHubPhotoExtension) {
    EHubPhotoExtension[".jpeg"] = ".jpeg";
    EHubPhotoExtension[".jpg"] = ".jpg";
    EHubPhotoExtension[".png"] = ".png";
    EHubPhotoExtension[".webp"] = ".webp";
})(EHubPhotoExtension || (exports.EHubPhotoExtension = EHubPhotoExtension = {}));
