"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMessagesAttachmentType = void 0;
var EMessagesAttachmentType;
(function (EMessagesAttachmentType) {
    EMessagesAttachmentType["AUDIO"] = "AUDIO";
    EMessagesAttachmentType["VIDEO"] = "VIDEO";
    EMessagesAttachmentType["DOCUMENT"] = "DOCUMENT";
    EMessagesAttachmentType["PHOTO"] = "PHOTO";
})(EMessagesAttachmentType || (exports.EMessagesAttachmentType = EMessagesAttachmentType = {}));
